import state from './post.state.js'
import mutations from './post.mutations.js'
import actions from './post.actions.js'
import getters from './post.getters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

