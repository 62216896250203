<template>
  <div>
    <!-- Alert: No item found -->
    <b-row v-if="!postData" class="match-height">
      <b-col md="12" lg="12">
        <b-card>
          <b-card-text> Une erreur s'est produite </b-card-text>
          <hr />
          Aucun article avec la référence {{ this.buildCurrentSlug() }} trouvé.
          Vérifier dans le module
          <b-link class="alert-link" :to="{ name: 'apps-securite-astreinte' }">
            Conformité </b-link
          >.
        </b-card>
      </b-col>
    </b-row>
    <b-form @submit.prevent="save">
      <b-row v-if="postData">
        <b-col md="9">
          <b-card>
            {{ postData.title }}
            <hr />

            <!-- CATEGORY -->
            <b-form-group label="* Catégorie" label-for="category">
              <v-select
                id="category"
                label="text"
                value="key"
                v-model="formdata.category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Sélectionner une categorie"
                :options="formdata.categories"
              />
            </b-form-group>
            <!-- TITLE -->
            <b-form-group label="* Titre" label-for="title">
              <b-form-input
                v-model="formdata.title"
                id="title"
                placeholder="Titre"
                required
              />
            </b-form-group>
            <!-- FILE -->
            <b-form-group label="Pièce jointe" label-for="file">
              <b-form-file
                @change="this.$coddyger.updateCurrFile"
                v-model="formdata.file"
                id="file"
                accept="application/pdf"
                placeholder="Sélectionner un fichier"
              />
              <b-button-group
                    size="sm"
                >
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="emptyFileInput"
                    v-if="formdata.file"
                    >
                    Retirer fichier
                    </b-button>
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    :href="this.$coddyger.buildPdf(postData.file)"
                    v-if="postData.file"
                    >
                    Afficher le fichier
                    </b-button>
                </b-button-group>
            </b-form-group>
            <!-- CONTENT -->
            <b-form-group label="Description" label-for="content">
              <b-form-textarea
                rows="4"
                v-model="formdata.content"
                id="content"
                placeholder="Description"
                
              />
            </b-form-group>

            <!-- submit and reset -->
            <b-row>
              <b-col>
                
                
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="ml-1 mb-1"
                  style="float: right"
                  :disabled="disableButtonOnLoading"
                  block
                >
                  <span v-if="!isLoading">Enregistrer</span>
                  <span v-if="isLoading">
                    <b-spinner small type="grow"></b-spinner>
                    Chargement...
                  </span>
                </b-button>

                <b-button
                  class="mt-4"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  :to="{name: 'apps-securite-astreinte'}"
                  block
                >
                  Voir la liste
                </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  BTooltip,
  BModal,
  VBModal,
  BCardText,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTimepicker,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BAlert,
  BButtonGroup
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import ModulePost from "@/store/modules/post/post.module";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as payloads from "./payloads";

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      path: "securite/astreinte",
      isLoading: false,
      postData: null,
      formdata: {
        slug: null,
        //-FORM-PROPERTIES-
        title: "",
        content: "",
        category: "",
        file: null,
        //-ASSETS-
        categories: payloads.category,
      },
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BTooltip,
    BModal,
    BCardText,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BSpinner,
    BAlert,
    BButtonGroup,

    vSelect,
    BFormTimepicker,
  },
  computed: {
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    if (!ModulePost.isRegistered) {
      this.$store.registerModule("ModulePost", ModulePost);
      ModulePost.isRegistered = true;
    }

    let slug = this.buildCurrentSlug();
    this.$store
      .dispatch("ModulePost/fetchItem", {
        slug: slug,
        path: this.path,
      })
      .then((res) => {
        this.postData = res;
        this.formdata.slug = res.slug;
        this.formdata.title = res.title;
        this.formdata.content = res.content;
        this.formdata.category = {
          key: res.category,
          text: res.categoryName,
        };
      })
      .catch((res) => {
        const { status, message, data } = res;
        //console.log(message)
      });
  },
  methods: {
    emptyFileInput() {
      this.formdata.file = null;
    },
    buildCurrentSlug() {
      let path = this.$router.history.current.path;
      let getLastItem = (thePath) =>
        thePath.substring(thePath.lastIndexOf("/") + 1);
      path = getLastItem(path);

      return path;
    },
    save() {
      if (this.formdata.title === "") {
        this.swalError("Erreur", this.buildRequiredMessage("Titre"), "error");
      } else if (this.formdata.category === "") {
        this.swalError(
          "Erreur",
          this.buildRequiredMessage("Categorie"),
          "error"
        );
      } else {
        let global = {};
        let formData = new FormData();

        formData.append("slug", this.formdata.slug);
        formData.append("file", this.formdata.file);
        formData.append("title", this.formdata.title);
        formData.append("content", this.formdata.content);
        formData.append("category", this.formdata.category.key);

        global = {
          formdata: formData,
          path: this.path,
        };

        if (this.formdata.slug !== null && this.formdata.slug.length >= 0) {
          this.isLoading = true;
          this.$store
            .dispatch("ModulePost/edit", global)
            .then(() => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Modification effectuée avec succès!`,
                },
              });
            })
            .catch((error) => {
              this.isLoading = false;
              this.swalError("Erreur", error.message, "error");
            });
        } else {
          this.swalError("Erreur", "Une erreur s'est produite", "error");
        }
      }
    },
    swalError(title, message, type) {
      this.$swal({
        title: title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
  },
};
</script>